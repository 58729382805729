import React from "react";
import IconFacebook from "../assets/icon-facebook";
import IconTwitter from "../assets/icon-twitter";
import IconInstagram from "../assets/icon-instagram";
import IconYoutube from "../assets/icon-youtube";
import UstwoLogo from "../assets/ustwo-logo";
import cssClass from "./footer.module.scss";

const Footer = () => (
  <footer className={cssClass.footer}>
    <div className={cssClass.inner}>
      <div className={cssClass.socialIcons}>
        <a href="https://www.facebook.com/ustwogames/"><IconFacebook /></a>
        <a href="https://twitter.com/WildlifeAlba"><IconTwitter /></a>
        <a href="https://www.instagram.com/wildlifealba/"><IconInstagram /></a>
        <a href="https://www.youtube.com/channel/UCkZdc6Wp8zokpOg-awiATNA" className={cssClass.youtube}><IconYoutube /></a>
      </div>
      <div className={cssClass.logo}><a href="https://ustwogames.co.uk"><UstwoLogo /></a></div>
    </div>
  </footer>
);

export default Footer;
