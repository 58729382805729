import React from "react";

const IconTwitter = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 23">
    <title>YouTube</title>
    <g>
      <path d="M20.699 37.863V21.49c0-1.657 1.343-3 3-3 .52 0 1.031.135 1.483.392l14.392 8.188c1.44.82 1.943 2.65 1.124 4.09-.267.47-.655.858-1.124 1.125l-14.392 8.187c-1.44.82-3.271.316-4.09-1.124-.258-.452-.393-.963-.393-1.484z" transform="translate(-1070 -5508) translate(0 5440) translate(810 50) translate(240)"/>
    </g>
  </svg>
);

export default IconTwitter;
