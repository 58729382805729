import React from "react";
import Footer from "../components/footer";
import Navigation from "./navigation";
import "../styles/index.scss";
import cssClass from "./layout.module.scss";

const Layout = ({ children, className }) => (
  <div className={`${cssClass.wrapper} ${className}`}>
    <Navigation />
    {children}
    <Footer />
  </div>
);

export default Layout;
