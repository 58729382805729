import React from "react";
import { Link } from "gatsby";
import cssClass from "./navigation.module.scss";

const Navigation = () => {
  return (
    <div className={cssClass.navigation}>
      <ul>
        <li><Link to="/" activeClassName={cssClass.active}>Home</Link></li>
        <li><Link to="/environment" activeClassName={cssClass.active}>Environment</Link></li>
      </ul>
    </div>
  );
};

export default Navigation;
